<template>
  <div :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedSemi overflow-hidden">

    <!-- Blank Email-->
    <v-sheet v-if="blank" class="d-flex align-center justify-center" height="314">
      <v-icon :color="wsBACKGROUND" size="100">mdi-assistant</v-icon>
    </v-sheet>

    <!-- Preview Email-->
    <v-sheet v-else height="314" style="position: relative" :color="bodyColor" class="overflow-hidden">
        <email-view
            :uuid="entity.value"
            preview
            :scale="scale"
            @body-color="bodyColor = $event"
        />

    </v-sheet>

    <!-- Description Block-->
    <v-sheet
        :color="wsLIGHTCARD"
        :style="`border-top: 1px solid ${wsBACKGROUND}`"
        class="pa-4 d-flex flex-column"
        :min-height="!templateViewer ? 170 : 140"
    >

      <h4 class="wsDARKER">{{ !blank ? (entity.name || $t('Title')) :  $t('mailer.templates.blank.title') }}</h4>
      <h5 class="wsACCENT font-weight-regular shorten-text-two mt-1 mb-4">{{  !blank ? entity.description || $t('Description') :  $t('mailer.templates.blank.description') }}</h5>

      <div class="flex-grow-1"/>

      <div v-if="!templateViewer" class="d-flex">

        <ws-button @click="select(entity.value)" label="Choose" outlined/>
        <ws-button
            v-if="!blank"
            @click="displayPreview = true" label="Preview" :color="wsACCENT" text
        />

      </div>


    </v-sheet>

    <ws-dialog
        v-if="displayPreview && !templateViewer"
        v-model="displayPreview"
        width="800"
        no-navigation
    >
      <email-viewer
          :uuid="entity.value"
          :width="800*0.7"
          preview
          :scale="0.7"
          @body-color="bodyColor = $event"
          disable-mobile-view
      />
    </ws-dialog>

  </div>
</template>

<script>
import emailView from "@modules/finemailer/components/emailConstructor/viewer/emailView.vue";
import emailViewer from "@modules/finemailer/components/emailConstructor/viewer/EmailViewer.vue";
export default {
  name: "emailTemplateCard",
  components : {
    emailView,
    emailViewer
  },
  props : {
    entity : {
      type : Object,
      default() { return {} }
    },
    blank : {
      type : Boolean,
      default : false
    },
    scale : {
      type : Number,
      default : 0.4
    },
    templateViewer : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      bodyColor : 'transparent',
      displayPreview : false
    }
  },
  methods : {
    select(value) {
      value = !this.blank ? value : null
      this.$emit('select' , value)
    },
    preview(value) {
      this.$emit('preview' , value)
    }
  }
}
</script>

<style scoped>

</style>